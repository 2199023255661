import React from 'react';

function Home() {
	return (
		<main className='snap-y snap-mandatory overflow-y-scroll h-screen'>
			<section className="h-screen flex justify-center items-center bg-emerald-600 text-slate-50 snap-start">
				<div>
					<h1 className="text-4xl sm:text-7xl font-bold drop-shadow">Welkom in de wachtkamer van 4YourFuture</h1><br />
					<h2 className="text-2xl font-bold drop-shadow">Even geduld aub..</h2>
				</div>
			</section>

			<section className="h-screen flex justify-center items-center bg-red-600 text-slate-50 snap-start">
				<h1 className="text-6xl sm:text-7xl font-bold drop-shadow">Over ons</h1>
			</section>

			<section className="h-screen flex justify-center items-center bg-indigo-600 text-slate-50 snap-start">
				<h1 className="text-6xl sm:text-7xl font-bold drop-shadow">Hoe wij werken</h1>
			</section>

			<section className="h-screen flex justify-center items-center bg-violet-600 text-slate-50 snap-start">
				<h1 className="text-6xl sm:text-7xl font-bold drop-shadow">Onze talentpool</h1>
			</section>
		</main>
	);
}

export default Home;
